
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/projectinfo";
@Options({
  name: "Personrecord",
})
export default class extends Vue {
  private activeNames = [];
  private reqdata = {};
  private projectImages: string[] = [
    "jianli.png",
    "jianshe.png",
    "sheji.png",
    "kancha.png",
    "shigong.png",
    "daijian.png",
    "zhuanye.png",
    "jianli.png",
    "jianshe.png",
    "sheji.png",
    "kancha.png",
    "shigong.png",
    "daijian.png",
    "zhuanye.png",
  ];

  protected goBack() {
    this.$router.go(-1);
  }

  protected btnClick(li: any) {
    this.$router.push({
      path: "/app/PersonrecordInfo",
      query: { deptId: li.nodeData.id },
    });
  }

  getPersonRecord() {
    api
      .mobileprojectdetail({
        projectId: this.$store.state.project.projectinfo.projectId,
      })
      .then((res) => {
        this.reqdata = res.data;
      });
  }

  @Watch("$route", { immediate: true })
  private routerChange(route: any) {
    this.getPersonRecord();
  }
}
